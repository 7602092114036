<template>
<div class="mt-3">
  <div class="row justify-content-center mb-4">
    <a-badge status="success" text="Verificados" class="mr-4"/>
    <a-badge status="warning" text="Pendientes" />
  </div>
  <!--TABLA-->
    <a-table  :scroll="{x:1300}"
        :columns="tableObject.columns"
        :data-source="tableObject.data"
        :pagination="tableObject.pagination"
        :loading="tableObject.loading"
        @change="FetchTable"
        :row-key="record => record.id" bordered="">
      <template slot="checksEquipmentSources" slot-scope="checksEquipmentSources">{{checksEquipmentSources.name}}</template>
      <template slot="details" slot-scope="data">
        <a-descriptions bordered size="small" :column="{ sm: 1}"
                        :class="(data.verifiers && data.verified_at) ? 'bg-info' : 'bg-warning'">
          <a-descriptions-item label="Fecha de Inspección">
            <strong>{{ moment(data.inspected_at).format('DD/MM/YYYY') }}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Fecha de Verificación" v-if="data.verified_at">
            <strong>{{ moment(data.verified_at).format('DD/MM/YYYY') }}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Inspector">
            <strong>{{ data.inspectors.name }}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Verificador" v-if="data.verifiers">
            <strong>{{ data.verifiers.name }}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Turno" v-if="data.operationShifts">
            <strong>{{ data.operationShifts.name }}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Nombre de Operador/Laboratorista" v-if="data.operator_name">
            {{ data.operator_name }}
          </a-descriptions-item>
          <a-descriptions-item label="Nombre de Técnico/Laboratorista" v-if="data.technical_nameinitial_hours">
            {{ data.technical_nameinitial_hours }}
          </a-descriptions-item>
          <a-descriptions-item label="Horómetro inicial" v-if="data.initial_hours">
            {{ data.initial_hours }}
          </a-descriptions-item>
          <a-descriptions-item label="Horómetro final" v-if="data.final_hours">
            {{ data.final_hours }}
          </a-descriptions-item>
          <a-descriptions-item label="No.Orden" v-if="data.order_number">
            {{ data.order_number }}
          </a-descriptions-item>
        </a-descriptions>
      </template>
      <p slot="expandedRowRender" slot-scope="data">
        <small>{{ data.comments }}</small>
      </p>
      <template slot="action" slot-scope="data">
        <b-button v-if="data.checksEquipmentSources"
                  @click="GoToCheckLis(data.checksEquipmentSources.id,data.id)"
           class="mb-1" variant="primary" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Ver Check List">
            <b-icon icon="card-checklist"/> Ver Check List
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Ver Check List">
            <b-icon icon="card-checklist"/>
          </div>
        </b-button>
      </template>
    </a-table>
  <!--TABLA-->
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'auxiliarMasterChecks',
  mixins: [fractalMixin],
  props: {
    auxiliarMasterId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Check',
            dataIndex: 'checksEquipmentSources',
            class: 'small text-center',
            scopedSlots: { customRender: 'checksEquipmentSources' },
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'details' },
            // width: '20%',
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            // width: '20%',
          },
        ],
      },
    }
  },
  methods: {
    async FetchTable(pagEvent = {}) {
      const params = {
        include: 'checksEquipmentSources,equipmentChecksAnswers,inspectors,verifiers,operationShifts',
        'filter[auxiliarMasters]': this.auxiliarMasterId,
      }
      await this.GetTableResource(pagEvent, 'equipmentChecks', this.tableObject, params)
    },
    // GoTo CheckList
    GoToCheckLis(checksEquipmentSource, equipmentCheck) {
      const routeData = this.$router.resolve({ name: 'equipmentChecks', params: { checksEquipmentSource: checksEquipmentSource, auxiliarMaster: this.auxiliarMasterId, equipmentCheck: equipmentCheck } })
      window.open(routeData.href, '_blank')
    },
  },
  async mounted() {
    await this.FetchTable()
  },
}
</script>

<style scoped>

</style>
