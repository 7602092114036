<template>
<div>
  <auxiliarMasterInfo :auxiliarMasterId="id"/>

  <h3>Historial</h3>
  <hr class="bg-primary">

  <a-tabs default-active-key="1">
    <a-tab-pane key="1" tab="CheckList">
      <auxiliarMasterChecks :auxiliarMasterId="id"/>
    </a-tab-pane>
    <a-tab-pane key="2" tab="Incidencias">
      <auxiliarMasterIncidents :auxiliarMasterId="id"/>
    </a-tab-pane>
  </a-tabs>
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import auxiliarMasterInfo from '../components/auxiliarMasterInfo'
import auxiliarMasterIncidents from '../components/auxiliarMasterIncidents'
import auxiliarMasterChecks from '../components/auxiliarMasterChecks'
export default {
  name: 'equipmentChecksHistory',
  mixins: [fractalMixin],
  components: {
    auxiliarMasterInfo,
    auxiliarMasterChecks,
    auxiliarMasterIncidents,
  },
  data() {
    return {
      id: this.$route.params.auxiliarMaster,
    }
  },
}
</script>

<style scoped>

</style>
