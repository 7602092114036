<template>
  <div class="mt-3">
    <div class="row justify-content-center mb-4">
      <a-badge status="success" text="Verificados" class="mr-4"/>
      <a-badge status="warning" text="Pendientes" />
    </div>
    <!--TABLA-->
    <a-table  :scroll="{x:1300}"
              :columns="tableObject.columns"
              :data-source="tableObject.data"
              :pagination="tableObject.pagination"
              :loading="tableObject.loading"
              @change="FetchTable"
              :row-key="record => record.id" bordered>
      <template slot="equipmentChecksAnswers" slot-scope="equipmentChecksAnswers">{{equipmentChecksAnswers.checksEquipmentQuestions.question}}</template>
      <template slot="details" slot-scope="data">
        <a-descriptions bordered size="small" :column="{ sm: 1}">
          <a-descriptions-item label="Fecha de Reporte" v-if="data.verified_at">
            <strong>{{ moment(data.verified_at).format('DD/MM/YYYY') }}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Estatus">
            <strong class="bg-primary px-2 pt-1 pb-2 rounded text-white "
            :class="{ 'bg-primary': data.status === 'Finalizado', 'bg-warning': data.status === 'En Proceso', 'bg-danger': data.status === 'Sin Atender', }">
              {{data.status}}
            </strong>
          </a-descriptions-item>
          <a-descriptions-item label="Informador">
            <strong>{{ data.users.name }}</strong>
          </a-descriptions-item>
        </a-descriptions>
      </template>
      <p slot="expandedRowRender" slot-scope="data">
        <small>{{ data.comments }}</small>
      </p>
      <template slot="action" slot-scope="data">
        <b-button @click="OpenModal(data)" v-if="data.status !== 'Finalizado'"
                  class="mb-1" variant="secondary" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/> Editar
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/>
          </div>
        </b-button>
        <span v-else> Sin Acción</span>
      </template>
    </a-table>
    <!--TABLA-->
    <!-- MODAL -->
    <b-modal title="Incidencia" ref="modalI" size="lg" @hide="CloseModal" >
      <b-container fluid>
        <!--FORMULARIO-->
        <a-form-model :model="resourceObj.form" :rules="formRules" ref="formI"
                      layout="vertical" :wrapper-col="{ span: 24}">
          <!--SELECT -Informador -->
          <a-form-model-item label="Informador" ref="users" prop="users" >
            <a-select v-model="resourceObj.form.users"
                      placeholder="Seleccionar Informador"
                      show-search :filter-option="filterOption">
              <a-select-option  v-for="option in Users" :key="option.id" :value="option.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT -Informador -->
          <a-row align="middle" justify="center" class="mb-4">
            <a-col :sm="{ span: 24 }" :md="{ span:12}">
              <!--SELECT -Estatus -->
              <a-form-model-item label="Estatus" ref="status" prop="status" >
                <a-select v-model="resourceObj.form.status"
                          placeholder="Seleccionar Estatus"
                          show-search :filter-option="filterOption">
                  <a-select-option  v-for="option in Estatus" :key="option.value" :value="option.text" >
                    {{option.text}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <!--SELECT -Estatus -->
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span:12}">
              <!--DATEPICKER - Fecha de Reporte-->
              <a-form-model-item  label="Fecha de Reporte" ref="verified_at" prop="verified_at" >
                <a-date-picker  v-model="resourceObj.form.verified_at" style="width: 100%"
                                placeholder="Fecha de Reporte"
                                format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"/>
              </a-form-model-item>
              <!--DATEPICKER - Fecha de Reporte -->
            </a-col>
          </a-row>
          <!--TEXT-AREA - Comentarios/Obervaciones-->
          <a-form-model-item label="Comentarios/Obervaciones" ref="comments"  prop="comments" >
            <a-textarea v-model="resourceObj.form.comments" :auto-size="{ minRows: 2, maxRows: 7 }"/>
          </a-form-model-item>
          <!--TEXT-AREA -Comentarios/Obervaciones -->
          <!--BOTONERA  -->
          <div class="row justify-content-end mr-2">
            <b-button-group>
              <b-button  variant="primary" @click="Submit()" pill>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/> Guardar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/>
                </div>
              </b-button>
            </b-button-group>
          </div>
          <!--BOTONERA  -->
        </a-form-model>
        <!--FORMULARIO-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'auxiliarMasterIncidents',
  mixins: [fractalMixin],
  props: {
    auxiliarMasterId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Pregunta',
            dataIndex: 'equipmentChecksAnswers',
            class: 'small text-center',
            scopedSlots: { customRender: 'equipmentChecksAnswers' },
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'details' },
            // width: '20%',
          },
          {
            title: 'Atendida',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            // width: '20%',
          },
        ],
      },
      spinnerLoad: false,
      resourceObj: {
        resourceType: 'equipmentIncidents',
        form: {
          id: '',
          equipmentChecksAnswers: undefined,
          auxiliarMasters: undefined,
          users: '',
          status: 'Sin Atender',
          verified_at: '',
          comments: '',
        },
        relationships: ['equipmentChecksAnswers', 'auxiliarMasters', 'users'],
      },
      formRules: {
        equipmentChecksAnswers: [{ required: true, message: 'La respuesta es obligatoria.', trigger: 'blur' }],
        auxiliarMasters: [{ required: true, message: 'El equipo es obligatorio.', trigger: 'blur' }],
        users: [{ required: true, message: 'El Informador es obligatorio.', trigger: 'blur' }],
        status: [{ required: true, message: 'El estatus es obligatorio.', trigger: 'blur' }],
      },
      Users: [],
      Estatus: [{ value: 1, text: 'Sin Atender' }, { value: 2, text: 'En Proceso' }, { value: 3, text: 'Finalizado' }],
    }
  },
  methods: {
    async FetchCombos() {
      this.Users = await this.GetResource('/users', { sort: 'name' })
    },
    async FetchTable(pagEvent = {}) {
      const params = {
        include: 'equipmentChecksAnswers.checksEquipmentQuestions,users,auxiliarMasters',
        'filter[auxiliarMasters]': this.auxiliarMasterId,
      }
      await this.GetTableResource(pagEvent, 'equipmentIncidents', this.tableObject, params)
    },
    OpenModal(data) {
      this.$refs.modalI.show()
      // Update case
      this.resourceObj.form.id = data.id
      this.resourceObj.form.equipmentChecksAnswers = data.equipmentChecksAnswers.id.toString()
      this.resourceObj.form.auxiliarMasters = data.auxiliarMasters.id.toString()
      this.resourceObj.form.users = data.users.id
      this.resourceObj.form.status = data.status
      this.resourceObj.form.verified_at = data.verified_at
      this.resourceObj.form.comments = data.comments
    },
    CloseModal() {
      this.$refs.modalI.hide()
      this.resourceObj.form = {
        id: '',
        equipmentChecksAnswers: undefined,
        auxiliarMasters: undefined,
        users: '',
        status: 'Sin Atender',
        verified_at: '',
        comments: '',
      }
    },
    Submit() {
      this.$refs.formI.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            // Post
            await this.PostResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else await this.PutResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    SuccessCallback() {
      this.FetchTable()
      this.spinnerLoad = false
      this.CloseModal()
    },
  },
  async mounted() {
    await this.FetchTable()
    await this.FetchCombos()
  },
}
</script>

<style scoped>

</style>
